<template>
  <div id="app">
    <template v-for="notification in notifications">
      <b-alert
        :key="notification.id"
        style="white-space: pre"
        :variant="notification.variant"
        :show="true">
        {{ notification.message }}
      </b-alert>
    </template>

    <div
      v-if="userProfileLoadingState.loading"
      class="d-flex justify-content-center mt-4">
      <b-spinner
        small
        type="grow"
        variant="primary" />
    </div>
    <router-view />
  </div>
</template>

<script>
////////////////////////
// Generic hotjar plugin - should be the same for all instances
if (process.env.VUE_APP_GENERIC_HOTJAR_SITE_ID) {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: process.env.VUE_APP_GENERIC_HOTJAR_SITE_ID, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}
////////////////////////

import { mapGetters } from "vuex";
import { registerLicense } from "@syncfusion/ej2-base";
import { syncfusionLicense } from "@/preferences";

registerLicense(syncfusionLicense);

export default {
  computed: {
    ...mapGetters(["notifications", "userProfileLoadingState"])
  },
  created() {
    // Watch for global system changes.
    // Ex.: User has no preselected theme in our app, but his global theme is dynamic
    //      This listener ensures we capture that event live
    const systemThemeLight = window.matchMedia("(prefers-color-scheme: light)");
    systemThemeLight.addEventListener("change", this.initTheme);
    this.$once("hook:beforeDestroy", () => {
      systemThemeLight.removeEventListener("change", this.initTheme);
    });
  },
  mounted() {
    this.initTheme();
  },
  methods: {
    initTheme() {
      this.$store.dispatch("initThemeFromPreferences");
    }
  }
};
</script>

<style lang="scss">
@import "scss/main.scss";
</style>
